import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/vectors/LIQ_SLATE.svg';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Drawer from '@mui/material/Drawer';
import { Box, Divider, MenuItem } from '@mui/material';

import Menu from '@mui/icons-material/MenuOpenRounded';
import Close from '@mui/icons-material/CloseRounded';
import AccountPanel from './AccountPanel';
import { Entity, EntityCategory, EntityRepo } from '../../features/config/EntityRepo';
import { EntityLink } from '../../features/navigation/UI/EntityDropDown';
import { Permissions } from '../../store/reducers/userSlice';

const link_tran = 'text-slate-600 hover:text-black';

// userEmail and userloading are required parameters to be passed to the account panel component
interface mobileNavbarProps {
    userEmail: string | null;
    userLoading: boolean;
    userPermissions?: Permissions;
    handleLogin: () => Promise<void>;
    handleSignUp: () => Promise<void>;
    handleLogout: () => void;
}

/*
    Component: MobileNavbar.tsx
    Params: 
        userEmail - Email of current user
        userloading - login loading status of current user
    Author: Will Brewer
    Desc:
    Mobile responsive navbar component. Fits to a dynamic viewport width and reduces visual clutter for
    mobile experience. Built custom with tailwind and conditional rendering. No library dependencies aside
    from MUI-icons used in the menu. Automatically closes on scroll away as well as clicking outside the menu.
*/
const MobileNavbar = ({
    userEmail,
    userLoading,
    userPermissions,
    handleLogin,
    handleSignUp,
    handleLogout,
}: mobileNavbarProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const coreEntities = EntityRepo.getEntityListByCategory(EntityCategory.CORE);
    const personalEntities = EntityRepo.getEntityListByCategory(EntityCategory.PERSONAL);
    const globalEntities = EntityRepo.getEntityListByCategory(EntityCategory.GLOBAL);

    const committeeEntities = EntityRepo.getEntityListByCategory(EntityCategory.COMMITTEE);
    const subscribeEntity = new Entity(
        'subscribe',
        'subscribe',
        '/pricing/committee',
        EntityCategory.COMMITTEE,
        1
    );

    return (
        <header className="text-slate-700 fixed z-10 w-full rounded-b-xl bg-white shadow-md">
            <Drawer anchor="top" open={open} onClose={handleDrawerClose}>
                <div className="flex flex-col text-lg space-y-2 justify-center mb-2 px-6 pt-6">
                    <Accordion
                        square={false}
                        sx={{
                            borderRadius: '5px',
                            backgroundColor: '#0284c7',
                            boxShadow: 'none',
                        }}
                    >
                        <AccordionSummary
                            sx={{
                                color: 'white',
                            }}
                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        >
                            Profile Dashboards
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                backgroundColor: 'rgb(248 250 252)',
                                borderRadius: '0px 0px 4px 4px',
                                padding: 0,
                                paddingTop: 1.5,
                                paddingBottom: 1.5,
                            }}
                        >
                            {coreEntities.map((entity: Entity, i: number) => (
                                <EntityLink
                                    key={i}
                                    entity={entity}
                                    handleClose={handleDrawerClose}
                                />
                            ))}
                            <Divider sx={{ marginY: 1 }} />
                            {personalEntities.map((entity: Entity, i: number) => (
                                <EntityLink
                                    key={i}
                                    entity={entity}
                                    handleClose={handleDrawerClose}
                                />
                            ))}
                            <Divider sx={{ marginY: 1 }} />
                            {globalEntities.map((entity: Entity, i: number) => (
                                <EntityLink
                                    key={i}
                                    entity={entity}
                                    handleClose={handleDrawerClose}
                                />
                            ))}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        square={false}
                        sx={{
                            borderRadius: '5px',
                            backgroundColor: '#0ea5e9',
                            boxShadow: 'none',
                        }}
                    >
                        <AccordionSummary
                            sx={{
                                color: 'white',
                            }}
                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        >
                            Committee Monitor
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                backgroundColor: 'rgb(248 250 252)',
                                borderRadius: '0px 0px 4px 4px',
                                padding: 0,
                                paddingTop: 1.5,
                                paddingBottom: 1.5,
                            }}
                        >
                            {committeeEntities.map((entity: Entity, i: number) => (
                                <EntityLink
                                    key={i}
                                    entity={entity}
                                    handleClose={handleDrawerClose}
                                />
                            ))}
                            <Divider sx={{ marginY: 1 }} />
                            <EntityLink entity={subscribeEntity} handleClose={handleDrawerClose} />
                        </AccordionDetails>
                    </Accordion>
                    <div className="bg-sky-400 rounded-md text-white">
                        <Link
                            className="px-4 h-[52px] flex flex-row justify-start items-center"
                            id="committee-monitor-button"
                            to="/issues"
                            onClick={handleDrawerClose}
                        >
                            Issue Tracker
                        </Link>
                    </div>
                    <Accordion
                        square={false}
                        sx={{
                            borderRadius: '5px',
                            backgroundColor: 'rgb(248 250 252)',
                            boxShadow: 'none',
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                            Features
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                backgroundColor: 'rgb(248 250 252)',
                                borderRadius: '0px 0px 4px 4px',
                            }}
                        >
                            <MenuItem
                                component={Link}
                                to="/features/tracking"
                                onClick={handleDrawerClose}
                            >
                                Issue Tracker
                            </MenuItem>

                            <MenuItem
                                component={Link}
                                to="/features/monitor"
                                onClick={handleDrawerClose}
                            >
                                Committee Monitor
                            </MenuItem>

                            <MenuItem
                                component={Link}
                                to="/features/dashboards"
                                onClick={handleDrawerClose}
                            >
                                <Box className={link_tran}>
                                    <p className="heading">Profile Dashboards</p>
                                </Box>
                            </MenuItem>

                            <MenuItem
                                component={Link}
                                to="/features/research"
                                onClick={handleDrawerClose}
                            >
                                <Box className={link_tran}>
                                    <p className="heading link_tran">Research Hub</p>
                                </Box>
                            </MenuItem>

                            <MenuItem
                                component={Link}
                                to="/features/gr-insider"
                                onClick={handleDrawerClose}
                            >
                                <Box className={link_tran}>
                                    <p className="heading">GR Insider</p>
                                </Box>
                            </MenuItem>
                        </AccordionDetails>
                    </Accordion>
                    <div className="bg-slate-50 rounded-md">
                        <MenuItem onClick={handleDrawerClose} component={Link} to="/custom-query">
                            Search Data
                        </MenuItem>
                    </div>

                    {userEmail && !userLoading ? (
                        <AccountPanel
                            userEmail={userEmail}
                            userLoading={userLoading}
                            userPermissions={userPermissions}
                            handleLogin={handleLogin}
                            handleLogout={handleLogout}
                            handleClose={handleDrawerClose}
                        />
                    ) : (
                        <div className="flex flex-row pt-5">
                            <button
                                className="w-1/2 mr-2 text-center border border-sky-700 text-sky-700 transition ease-in-out hover:bg-sky-100 rounded-xl py-1"
                                onClick={handleLogin}
                            >
                                Sign In
                            </button>
                            <Divider
                                orientation="vertical"
                                sx={{
                                    borderColor: 'gray',
                                }}
                                flexItem
                            />
                            <button
                                className="w-1/2 ml-2 text-center bg-sky-700 text-white transition ease-in-out hover:bg-sky-600 rounded-xl py-1"
                                onClick={handleSignUp}
                            >
                                Sign Up
                            </button>
                        </div>
                    )}

                    <div className="flex flex-row items-center justify-between px-4 py-4">
                        <Link to="/" onClick={handleDrawerClose}>
                            <img className="w-8" src={logo} alt="LIQ logo" />
                        </Link>
                        <button onClick={handleDrawerClose}>
                            <Close fontSize="large" color="error" />
                        </button>
                    </div>
                </div>
            </Drawer>
            <div className="flex flex-row items-center justify-between px-6 py-3">
                <Link
                    to="/"
                    className="text-xs flex flex-row items-center gap-3 leading-4 text-sky-700"
                >
                    <img className="w-8" src={logo} alt="LIQ logo" />
                    Technology for
                    <br />
                    Government Relations
                </Link>
                <div className="space-x-4">
                    <button onClick={handleDrawerOpen}>
                        <Menu fontSize="medium" />
                    </button>
                </div>
            </div>
        </header>
    );
};

export default MobileNavbar;
