import axios from 'axios';
import {
    entityCluster,
    keywordMonthRaw,
    entityLobbyingActivity,
    entityRegistration,
    entityOverviews,
} from '../../../interfaces/generic-entity.interface';
import {
    governmentAnnouncement,
    issueInfo,
    issueSummary,
    keywordMentions,
    Tweet,
} from '../interface/issue-entity.interface';
import {
    DPOHLinkFormatter,
    MPLinkFormatter,
    consultantLinkFormatter,
    institutionLinkFormatter,
    lobbyFirmLinkFormatter,
    organizationLinkFormatter,
    sectorLinkFormatter,
    senatorLinkFormatter,
} from '../../../helpers/internal_routing';
import { bumperSuperList } from '../../../interfaces/bumper-types.interface';
import {
    committeeMeetingLinkFormatter,
    govAnnouncementLinkFormatter,
    twitterLinkFormatter,
} from '../../../helpers/external_routing';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';
import moment from 'moment';

// Base URL for accessing the committee API
const baseURL = process.env.REACT_APP_API_BASE_URL;

// Fetch an overview of a given issue
const fetchUpdates = async (code: string | undefined, date: string) => {
    const response = await axios.get(`${baseURL}/issue/${code}/updates?date=${date}`);
    const data: recentUpdates = response.data;
    return data;
};

// Fetch Issue summary
const fetchIssueSummary = async (code: string | undefined, date: string): Promise<issueSummary> => {
    const summary_response = await axios.get(`${baseURL}/issue/${code}/summary?date=${date}`);
    const summary_data: issueSummary = summary_response.data;

    return summary_data;
};

// Fetch relevant government announcements for a given issue
const fetchGovernmentAnnouncements = async (code: string | undefined, date: string) => {
    const response = await axios.get(`${baseURL}/issue/${code}/announcements?date=${date}`);
    const data: governmentAnnouncement[] = response.data.announcements.map(
        (entry: governmentAnnouncement) => {
            return {
                ...entry,
                title: govAnnouncementLinkFormatter(entry.link, entry.title),
            };
        }
    );
    return data;
};

// Fetch an overview of a given issue
const fetchKeywordMentions = async (code: string | undefined, date: string) => {
    const mentionsResponse = await axios.get(
        `${baseURL}/issue/${code}/keywordsSentenceCounts?date=${date}`
    );
    const mentionsData: keywordMentions[] = mentionsResponse.data.issueKeywords;
    return mentionsData;
};

// Fetch info for a given issue
const fetchInfo = async (code: string | undefined) => {
    const issueInfoResponse = await axios.get(`${baseURL}/issue/${code}/info`);
    const issueInfoData: issueInfo = issueInfoResponse.data;
    return issueInfoData;
};

// Fetch an overview of a given issue
const fetchOverview = async (code: string | undefined, date: string) => {
    const overviewResponse = await axios.get(`${baseURL}/issue/${code}/overviews?date=${date}`);
    const overviewData: entityOverviews = overviewResponse.data;
    return overviewData;
};

// Fetch an overview of tweets for a given issue
const fetchSocialOverview = async (code: string, date: string) => {
    const overviewResponse = await axios.get(
        `${baseURL}/issue/${code}/social-overview?date=${date}`
    );
    const overviewData: any[] = overviewResponse.data.issueSocial;
    return overviewData;
};

// Fetch meeting sentences from recent meetings
const fetchSentences = async (
    code: string | undefined,
    date: string | undefined
): Promise<bumperSuperList[]> => {
    const sentence_response = await axios.get(
        `${baseURL}/issue/${code}/sentences?limit=6&date=${date}`
    );
    const sentence_data: any[] = sentence_response.data;

    const sentence_list: any[] = sentence_data.map((entry: keywordMonthRaw) => {
        return {
            ...entry,
            title:
                entry.meetingNo &&
                entry.parliament &&
                entry.session &&
                entry.committee &&
                entry.committee !== 'HANS'
                    ? committeeMeetingLinkFormatter(
                          entry.committee,
                          entry.parliament,
                          entry.session,
                          entry.meetingNo,
                          entry.title
                              ? `${entry.committee} (Meeting ${entry.meetingNo} - ${entry.date}): ${entry.title} `
                              : `${entry.committee}: ${entry.date} - Meeting ${entry.meetingNo}`
                      )
                    : entry.title && entry.meetingNo && entry.date
                    ? `${entry.committee}: ${entry.title} (Meeting ${entry.meetingNo} - ${entry.date})`
                    : entry.meetingNo
                    ? `${entry.committee}: ${entry.date} - Meeting ${entry.meetingNo}`
                    : entry.date
                    ? `${entry.committee}: ${entry.date}`
                    : '',
            lists: entry.datasets,
        };
    });

    return sentence_list;
};

// Fetch recent tweets in a given issue
const fetchTweets = async (code: string, date: string | undefined) => {
    const response = await axios.get(`${baseURL}/issue/${code}/tweets?date=${date}`);
    const data: any[] = response.data;
    const converted_data: Tweet[] = data.map((entry) => {
        const link =
            entry.is_mp && entry.name
                ? MPLinkFormatter(entry.name)
                : entry.id
                ? senatorLinkFormatter(entry.id, entry.name)
                : entry.name;
        return {
            ...entry,
            name: link,
            tweet: twitterLinkFormatter(entry),
        };
    });
    return converted_data;
};

// Fetch recent lobbying communications in a given issue
const fetchRecentLobbyingActivity = async (code: string, date: string | undefined) => {
    const response = await axios.get(`${baseURL}/issue/${code}/activity?date=${date}`);
    const data: entityLobbyingActivity[] = response.data;
    const converted_data: entityLobbyingActivity[] = data.map((entry) => {
        return {
            ...entry,
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            sector:
                entry.sector_id && entry.sector
                    ? sectorLinkFormatter(entry.sector_id, entry.sector)
                    : entry.sector,
            dpoh:
                entry.institution_id && entry.institution
                    ? DPOHLinkFormatter(entry.institution_id, entry.institution, entry.dpoh)
                    : entry.dpoh,
            institution:
                entry.institution_id && entry.institution
                    ? institutionLinkFormatter(entry.institution_id, entry.institution)
                    : entry.institution,
            lobby_firm:
                entry.lobby_firm !== 'In-House'
                    ? lobbyFirmLinkFormatter(entry.lobby_firm)
                    : 'In-House',
            consultant:
                entry.consultant === 'In-House'
                    ? 'In-House'
                    : entry.lobby_firm === 'In-House'
                    ? entry.consultant
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/cmmLgPblcVw?comlogId=${entry.comm}`,
        };
    });
    return converted_data;
};

// Fetch recent registrations in a given issue
const fetchRecentRegistrations = async (
    code: string,
    date: string | undefined
): Promise<entityRegistration[]> => {
    const response = await axios.get(`${baseURL}/issue/${code}/recentregistrations?date=${date}`);
    const data: entityRegistration[] = response.data;
    const converted_data: entityRegistration[] = data.map((entry) => {
        return {
            ...entry,
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            sector:
                entry.sector_id && entry.sector
                    ? sectorLinkFormatter(entry.sector_id, entry.sector)
                    : entry.sector,
            lobby_firm:
                entry.lobby_firm === 'In-House'
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.consultant === 'In-House'
                    ? 'In-House'
                    : entry.lobby_firm === 'In-House'
                    ? entry.consultant
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/vwRg?cno=${entry.corp}&regId=${entry.registration}#regStart`,
            dashboard: `link:/registrations/${entry.registration}`,
        };
    });
    return converted_data;
};

// Fetch recent deregistrations in a given issue
const fetchRecentDeregistrations = async (
    code: string,
    date: string | undefined
): Promise<entityRegistration[]> => {
    const response = await axios.get(`${baseURL}/issue/${code}/recentderegistrations?date=${date}`);
    const data: entityRegistration[] = response.data;
    const converted_data: entityRegistration[] = data.map((entry) => {
        return {
            ...entry,
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            sector:
                entry.sector_id && entry.sector
                    ? sectorLinkFormatter(entry.sector_id, entry.sector)
                    : entry.sector,
            lobby_firm:
                entry.lobby_firm === 'In-House'
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.consultant === 'In-House'
                    ? 'In-House'
                    : entry.lobby_firm === 'In-House'
                    ? entry.consultant
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/vwRg?regId=${entry.registration}&cno=${entry.corp}#regStart`,
            dashboard: `link:/registrations/${entry.registration}`,
        };
    });
    return converted_data;
};

// Fetch an issue data cluster of the given issue code, under a specific "type"
const fetchCluster = async (
    code: string | undefined,
    type:
        | 'organizations'
        | 'institutions'
        | 'lobbyfirms'
        | 'dpoh'
        | 'subject'
        | 'sectors'
        | 'mentions',
    date: string | undefined
): Promise<entityCluster> => {
    // Fetch top 6 *type* within the given sector (determined by YTD communication count)
    const cluster_response = await axios.get(
        `${baseURL}/issue/${code}/${type === 'mentions' ? '' : 'cluster/'}${type}?date=${date}`
    );
    const cluster_data: entityCluster = cluster_response.data;
    return cluster_data;
};

const fetchClusterV2 = async (
    code: number,
    entityTarget: 'institution' | 'sector' | 'organization' | 'firm' | 'dpoh' | 'subject',
    date: string
): Promise<entityCluster> => {
    const endDate = date;
    const startDate = moment(date).subtract(12, 'months').format('YYYY-MM-DD');

    const cluster_response = await axios.get(`${baseURL}/communications/cluster`, {
        headers: {
            dateStart: startDate,
            dateEnd: endDate,
            issueId: code,
            entityTarget: entityTarget,
            moversLimit: 25,
        },
    });
    const cluster_data: entityCluster = cluster_response.data;
    return cluster_data;
};

const createOrUpdateIssue = async (
    issueName: string,
    issueKeywords: string[],
    issueId: number | null
) => {
    return await axios.post(`${baseURL}/issue/create`, {
        issueName,
        issueKeywords,
        issueId,
    });
};

const deleteIssue = async (issueId: number) => {
    await axios.delete(`${baseURL}/issue/delete/${issueId}`);
};

export const issueAPI = {
    fetchUpdates,
    fetchIssueSummary,
    fetchGovernmentAnnouncements,
    fetchTweets,
    fetchKeywordMentions,
    fetchInfo,
    fetchOverview,
    fetchSocialOverview,
    fetchSentences,
    fetchRecentLobbyingActivity,
    fetchRecentRegistrations,
    fetchRecentDeregistrations,
    fetchCluster,
    fetchClusterV2,
    createOrUpdateIssue,
    deleteIssue,
};
