import React, { useState } from 'react';
import { Fade, Grow } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { entityOverview } from '../../../interfaces/generic-entity.interface';
import { keywordMentions } from '../interface/issue-entity.interface';
import Modal from '../../../components/misc/Modal';
import { MissingData } from '../../../components/misc/MissingData';
import BarChart from '../../../components/generic-dashboards/BarChart';
import { COLORS } from '../../../constants/colors';
import LoadingBarChart from '../../../components/generic-dashboards/loading/LoadingBarChart';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';
import {
    monthlyKeywords,
    monthlyMpList,
    overallActivity,
} from '../../../components/whats-new/IssueWhatsNew';

// Title and subtitle are optional components with defaults specified in function signature below.
interface overviewProps {
    topic?: string;
    dashboard?: string;
    title?: string;
    subtitle?: string | null;
    tooltipContent?: JSX.Element;
    lobbyingOverview: entityOverview[] | null;
    lobbyingOverviewloading: boolean;
    mentionOverview: keywordMentions[] | null;
    mentionOverviewloading: boolean;
    socialOverview: any[] | null;
    socialOverviewloading: boolean;
    updates: recentUpdates | null;
    updatesLoading: boolean;
}

/*
    Component: Overview.tsx
    Params:
        title - title of the row
        subtitle - secondary title of the row
    Author: Will Brewer
    Desc: Wrapper component for overview level dashboard rows, features a table and large bar chart
*/
const Overview = ({
    title = 'Title',
    subtitle = 'Subtitle',
    tooltipContent,
    lobbyingOverview,
    lobbyingOverviewloading,
    mentionOverview,
    mentionOverviewloading,
    socialOverview,
    socialOverviewloading,
    updates,
    updatesLoading,
}: overviewProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    return (
        <Fade in={true}>
            <div>
                <div className="flex lg:flex-row flex-col justify-between">
                    <div className="text-xl">{title}</div>
                    <div className="flex flex-row items-center">
                        <div className="text-lg font-light mr-2">{subtitle}</div>
                        <div className="hover: cursor-pointer" onClick={() => setModalOpen(true)}>
                            <HelpIcon color="info" />
                        </div>
                    </div>
                </div>

                <Modal
                    title={title}
                    open={modalOpen}
                    width={50}
                    onClose={() => setModalOpen(false)}
                >
                    {tooltipContent}
                </Modal>

                <hr className="h-0.5 my-3 bg-slate-100 rounded" />

                {updatesLoading ? (
                    <></>
                ) : !updatesLoading && !updates ? (
                    <MissingData />
                ) : updates ? (
                    <ul className="my-6 list-disc list-inside">
                        {updates.keywordMentions ? monthlyKeywords(updates) : <></>}
                        {updates.mpList ? monthlyMpList(updates) : <></>}
                        {overallActivity(updates)}
                    </ul>
                ) : (
                    <></>
                )}

                <div className="flex flex-col lg:grid lg:grid-cols-3 gap-4">
                    {mentionOverviewloading ? (
                        <LoadingBarChart />
                    ) : !mentionOverviewloading && !mentionOverview ? (
                        <MissingData />
                    ) : mentionOverview ? (
                        <div className="col-span-1">
                            <Grow in={true}>
                                <div>
                                    <BarChart
                                        title="Issue Keyword Mentions in Hansard and Committee Meetings"
                                        primaryColor={COLORS[1].primary}
                                        secondaryColor={COLORS[1].secondary}
                                        data={[...mentionOverview].slice(0, 12).reverse()}
                                        dataKey="count"
                                        xKey="mn"
                                        yKey="count"
                                        average
                                    />
                                </div>
                            </Grow>
                        </div>
                    ) : (
                        <></>
                    )}

                    {lobbyingOverviewloading ? (
                        <LoadingBarChart />
                    ) : !lobbyingOverviewloading && !lobbyingOverview ? (
                        <MissingData />
                    ) : lobbyingOverview ? (
                        <div className="col-span-1">
                            <Grow in={true}>
                                <div>
                                    <BarChart
                                        title="Monthly Lobbying on Issue"
                                        primaryColor={COLORS[3].primary}
                                        secondaryColor={COLORS[3].secondary}
                                        data={[...lobbyingOverview].slice(0, 12).reverse()}
                                        dataKey="count"
                                        xKey="month"
                                        yKey="count"
                                        average
                                    />
                                </div>
                            </Grow>
                        </div>
                    ) : (
                        <></>
                    )}

                    {socialOverviewloading ? (
                        <LoadingBarChart />
                    ) : !socialOverviewloading && !socialOverview ? (
                        <MissingData />
                    ) : socialOverview ? (
                        <div className="col-span-1">
                            <Grow in={true}>
                                <div>
                                    <BarChart
                                        title="Monthly MP Tweets on Issue"
                                        primaryColor={COLORS[0].primary}
                                        secondaryColor={COLORS[0].secondary}
                                        data={[...socialOverview].slice(0, 12).reverse()}
                                        dataKey="count"
                                        xKey="mn"
                                        yKey="count"
                                        average
                                    />
                                </div>
                            </Grow>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Fade>
    );
};

export default Overview;
