import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import moment from 'moment';
import IssueDashboard from '../UI/IssueDashboard';
import {
    fetchDPOHCluster,
    fetchFirmCluster,
    fetchInstitutionCluster,
    fetchIssueInfo,
    fetchIssueOverview,
    fetchIssueRecentLobbying,
    fetchIssueRegistrations,
    fetchOrganizationCluster,
    fetchSentences,
    fetchSubjectCluster,
    selectDPOHCluster,
    selectDPOHClusterLoading,
    selectFirmCluster,
    selectFirmClusterLoading,
    selectInstitutionCluster,
    selectInstitutionClusterLoading,
    selectIssueInfo,
    selectIssueInfoLoading,
    selectIssueOverview,
    selectIssueOverviewLoading,
    selectIssueRecentLobbying,
    selectIssueRecentLobbyingLoading,
    selectIssueRegistrations,
    selectIssueRegistrationsLoading,
    selectIssueSentences,
    selectIssueSentencesLoading,
    selectOrganizationCluster,
    selectOrganizationClusterLoading,
    selectSubjectCluster,
    selectSubjectClusterLoading,
    selectIssueExportFail,
    selectIssueExportLoading,
    fetchIssueUpdates,
    selectIssueUpdates,
    selectIssueUpdatesLoading,
    fetchKeywordMentions,
    selectIssueKeywordMentions,
    selectIssueKeywordMentionsLoading,
    fetchSectorCluster,
    selectSectorCluster,
    selectSectorClusterLoading,
    selectMentionCluster,
    selectMentionClusterLoading,
    fetchMentionCluster,
    fetchIssueTweets,
    selectIssueTweets,
    selectIssueTweetsLoading,
    selectIssueSocialOverview,
    selectIssueSocialOverviewLoading,
    fetchIssueSocialOverview,
    fetchIssueGovernmentAnnouncements,
    selectIssueGovernmentAnnouncements,
    selectIssueGovernmentAnnouncementsLoading,
    selectIssueSummary,
    selectIssueSummaryLoading,
    fetchIssueSummary,
} from '../store/issueSlice';
import { ProductType, selectUserPermissions } from '../../../store/reducers/userSlice';
import { SubscriptionRequired } from '../../../pages/misc/SubscriptionRequired';
import {
    entityCluster,
    entityLobbyingActivity,
    entityOverviews,
    entityRegistration,
} from '../../../interfaces/generic-entity.interface';
import { months } from '../../../constants/date_time';
import { SectorGovernmentInstitutions } from '../../sector-dashboards/properties/SectorGovernmentInstitutions';
import { SectorPublicOffice } from '../../sector-dashboards/properties/SectorPublicOffice';
import { SectorSubjects } from '../../sector-dashboards/properties/SectorSubjects';
import { SectorLobbyFirms } from '../../sector-dashboards/properties/SectorLobbyFirms';
import {
    governmentAnnouncement,
    issueInfo,
    keywordMentions,
    Tweet,
} from '../interface/issue-entity.interface';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';
import { IssueMentions, IssueOrganizations, IssueSectors } from '../properties/Tooltips';

const IssueDataLayer = () => {
    const dispatch = useAppDispatch();

    // Get the id of the current entity
    const urlParams = useParams();

    const entityCode = urlParams.entityId !== undefined ? urlParams.entityId : '';

    const [date, setDate] = useState<string>(moment(new Date()).format('YYYY-MM-DD'));

    const announcements: governmentAnnouncement[] | null = useSelector(
        selectIssueGovernmentAnnouncements
    );
    const announcements_loading: boolean = useSelector(selectIssueGovernmentAnnouncementsLoading);

    const info: issueInfo | null = useSelector(selectIssueInfo);
    const info_loading: boolean = useSelector(selectIssueInfoLoading);

    const overview: entityOverviews | null = useSelector(selectIssueOverview);
    const overviewLoading: boolean = useSelector(selectIssueOverviewLoading);

    const socialOverview: any[] | null = useSelector(selectIssueSocialOverview);
    const socialOverviewLoading: boolean = useSelector(selectIssueSocialOverviewLoading);

    const updates: recentUpdates | null = useSelector(selectIssueUpdates);
    const updates_loading: boolean = useSelector(selectIssueUpdatesLoading);

    const tweets: Tweet[] | null = useSelector(selectIssueTweets);
    const tweetsLoading: boolean = useSelector(selectIssueTweetsLoading);

    const keywordMention: keywordMentions[] | null = useSelector(selectIssueKeywordMentions);
    const keywordMentionLoading: boolean = useSelector(selectIssueKeywordMentionsLoading);

    const sentences: any[] | null = useSelector(selectIssueSentences);
    const sentencesLoading: boolean = useSelector(selectIssueSentencesLoading);

    const activity: entityLobbyingActivity[] | null = useSelector(selectIssueRecentLobbying);
    const activityLoading: boolean = useSelector(selectIssueRecentLobbyingLoading);

    const registrations: entityRegistration[] | null = useSelector(selectIssueRegistrations);
    const registrationsLoading: boolean = useSelector(selectIssueRegistrationsLoading);

    const mention_cluster: entityCluster | null = useSelector(selectMentionCluster);
    const mention_cluster_loading: boolean = useSelector(selectMentionClusterLoading);

    const org_cluster: entityCluster | null = useSelector(selectOrganizationCluster);
    const org_cluster_loading: boolean = useSelector(selectOrganizationClusterLoading);

    const sector_cluster: entityCluster | null = useSelector(selectSectorCluster);
    const sector_cluster_loading: boolean = useSelector(selectSectorClusterLoading);

    const institution_cluster: entityCluster | null = useSelector(selectInstitutionCluster);
    const institution_cluster_loading: boolean = useSelector(selectInstitutionClusterLoading);

    const firm_cluster: entityCluster | null = useSelector(selectFirmCluster);
    const firm_cluster_loading: boolean = useSelector(selectFirmClusterLoading);

    const dpoh_cluster: entityCluster | null = useSelector(selectDPOHCluster);
    const dpoh_cluster_loading: boolean = useSelector(selectDPOHClusterLoading);

    const subject_cluster: entityCluster | null = useSelector(selectSubjectCluster);
    const subject_cluster_loading: boolean = useSelector(selectSubjectClusterLoading);

    const issue_summary = useSelector(selectIssueSummary);
    const issue_summary_loading = useSelector(selectIssueSummaryLoading);

    const export_fail = useSelector(selectIssueExportFail);
    const export_loading = useSelector(selectIssueExportLoading);

    const permissions = useSelector(selectUserPermissions);

    const overviewHeaders: string[] = ['count'];
    // Get the current year
    const currentDate: moment.Moment = moment(date, 'YYYY-MM-DD');

    // Populate the list with the last 12 months
    for (let i = 0; i < 12; i++) {
        overviewHeaders.push(
            String(months[currentDate.month()]).slice(0, 3) +
                " '" +
                String(currentDate.year()).slice(2)
        );
        currentDate.subtract(1, 'month');
    }

    useEffect(() => {
        if (permissions?.issues || permissions?.productType === ProductType.TRIAL) {
            dispatch(fetchIssueUpdates({ code: entityCode, date: date }));
            dispatch(fetchIssueGovernmentAnnouncements({ code: entityCode, date: date }));
            dispatch(fetchIssueTweets({ code: entityCode, date: date }));
            dispatch(fetchKeywordMentions({ code: entityCode, date: date }));
            dispatch(fetchIssueInfo({ code: entityCode }));
            dispatch(fetchIssueOverview({ code: entityCode, date: date }));
            dispatch(fetchIssueSocialOverview({ code: entityCode, date: date }));
            dispatch(fetchSentences({ code: entityCode, date: date }));
            dispatch(fetchIssueRecentLobbying({ code: entityCode, date: date }));
            dispatch(fetchIssueRegistrations({ code: entityCode, date: date }));
            dispatch(fetchMentionCluster({ code: entityCode, date: date }));
            dispatch(fetchOrganizationCluster({ code: entityCode, date: date }));
            dispatch(fetchSectorCluster({ code: entityCode, date: date }));
            dispatch(fetchInstitutionCluster({ code: entityCode, date: date }));
            dispatch(fetchFirmCluster({ code: entityCode, date: date }));
            dispatch(fetchDPOHCluster({ code: entityCode, date: date }));
            dispatch(fetchSubjectCluster({ code: entityCode, date: date }));
            dispatch(fetchIssueSummary({ code: entityCode, date: date }));
        }
    }, [dispatch, entityCode, date, permissions]);

    return permissions?.issues || permissions?.productType === ProductType.TRIAL ? (
        <IssueDashboard
            date={date}
            handleDateChange={setDate}
            info={info}
            infoLoading={info_loading}
            announcements={announcements}
            announcementsLoading={announcements_loading}
            updates={updates}
            updatesLoading={updates_loading}
            issueSummary={issue_summary}
            issueSummaryLoading={issue_summary_loading}
            tweets={tweets}
            tweetsLoading={tweetsLoading}
            keywordMentions={keywordMention}
            keywordMentionsLoading={keywordMentionLoading}
            overview={overview}
            overviewLoading={overviewLoading}
            socialOverview={socialOverview}
            socialOverviewLoading={socialOverviewLoading}
            recentLobbying={activity}
            recentLobbyingLoading={activityLoading}
            recentRegistrations={registrations}
            recentRegistrationsLoading={registrationsLoading}
            bumperData={sentences}
            bumperDataLoading={sentencesLoading}
            exportFail={export_fail}
            exportLoading={export_loading}
            clusters={[
                {
                    cluster_data: mention_cluster,
                    title: 'MP Mentions of issue',
                    tooltipContent: IssueMentions,
                    loading: mention_cluster_loading,
                },
                {
                    cluster_data: sector_cluster,
                    title: 'Sectors Lobbying on Issue',
                    tooltipContent: IssueSectors,
                    loading: sector_cluster_loading,
                },
                {
                    cluster_data: org_cluster,
                    title: 'Organizations Lobbying on Issue',
                    tooltipContent: IssueOrganizations,
                    loading: org_cluster_loading,
                },
                {
                    cluster_data: institution_cluster,
                    title: 'Institutions Lobbied on Issue',
                    tooltipContent: SectorGovernmentInstitutions,
                    loading: institution_cluster_loading,
                },
                {
                    cluster_data: dpoh_cluster,
                    title: 'DPOHs Lobbied on Issue',
                    tooltipContent: SectorPublicOffice,
                    loading: dpoh_cluster_loading,
                },
                {
                    cluster_data: subject_cluster,
                    title: 'Subjects of Lobbying Communications',
                    tooltipContent: SectorSubjects,
                    loading: subject_cluster_loading,
                },
                {
                    cluster_data: firm_cluster,
                    title: 'Lobby Firms',
                    tooltipContent: SectorLobbyFirms,
                    loading: firm_cluster_loading,
                },
            ]}
        />
    ) : (
        <SubscriptionRequired />
    );
};

export default IssueDataLayer;
