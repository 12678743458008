import axios from 'axios';
import {
    entityLobbyingActivity,
    entityOverviews,
    entityRegistration,
    entityCluster,
} from '../../../interfaces/generic-entity.interface';
import {
    DPOHLinkFormatter,
    consultantLinkFormatter,
    institutionLinkFormatter,
    lobbyFirmLinkFormatter,
    organizationLinkFormatter,
} from '../../../helpers/internal_routing';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';

// Base URL for accessing the sector API
const baseURL = process.env.REACT_APP_API_BASE_URL;

// Fetch an overview of a given sector
const fetchUpdates = async (code: string | undefined, date: string) => {
    const response = await axios.get(`${baseURL}/sector/${code}/updates?date=${date}`);
    const data: recentUpdates = response.data;
    return data;
};

// Fetch an overview of a given sector
const fetchOverview = async (code: string | undefined, date: string) => {
    const overview_response = await axios.get(`${baseURL}/sector/${code}/overviews?date=${date}`);
    const overview: entityOverviews = overview_response.data;
    return overview;
};

// Fetch recent lobbying communications in a given sector
const fetchRecentLobbyingActivity = async (
    sector_code: string,
    sector_name: string,
    date: string | undefined
) => {
    const response = await axios.get(
        `${baseURL}/sector/${sector_code}/activity?limit=25&date=${date}`
    );
    const data: entityLobbyingActivity[] = response.data;
    const converted_data: entityLobbyingActivity[] = data.map((entry) => {
        return {
            ...entry,
            organization: organizationLinkFormatter(sector_code, sector_name, entry.organization!),
            dpoh:
                entry.institution_id && entry.institution
                    ? DPOHLinkFormatter(entry.institution_id, entry.institution, entry.dpoh)
                    : entry.dpoh,
            institution:
                entry.institution_id && entry.institution
                    ? institutionLinkFormatter(entry.institution_id, entry.institution)
                    : entry.institution,
            lobby_firm:
                entry.lobby_firm !== 'In-House'
                    ? lobbyFirmLinkFormatter(entry.lobby_firm)
                    : 'In-House',
            consultant:
                entry.consultant === 'In-House'
                    ? 'In-House'
                    : entry.lobby_firm === 'In-House'
                    ? entry.consultant
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/cmmLgPblcVw?comlogId=${entry.comm}`,
        };
    });
    return converted_data;
};

// Fetch recent registrations in a given sector
const fetchRecentRegistrations = async (
    sector_code: string,
    sector_name: string,
    date: string | undefined
): Promise<entityRegistration[]> => {
    const response = await axios.get(
        `${baseURL}/sector/${sector_code}/recentregistrations?limit=25&updated=true&new=true&date=${date}`
    );
    const data: entityRegistration[] = response.data;
    const converted_data: entityRegistration[] = data.map((entry) => {
        return {
            ...entry,
            organization: organizationLinkFormatter(sector_code, sector_name, entry.organization!),
            lobby_firm:
                entry.lobby_firm === 'In-House'
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.consultant === 'In-House'
                    ? 'In-House'
                    : entry.lobby_firm === 'In-House'
                    ? entry.consultant
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/vwRg?cno=${entry.corp}&regId=${entry.registration}#regStart`,
            dashboard: `link:/registrations/${entry.registration}`,
        };
    });
    return converted_data;
};

// Fetch recent deregistrations in a given sector
const fetchRecentDeregistrations = async (
    sector_code: string,
    sector_name: string,
    date: string | undefined
): Promise<entityRegistration[]> => {
    const response = await axios.get(
        `${baseURL}/sector/${sector_code}/recentderegistrations?limit=25&date=${date}`
    );
    const data: entityRegistration[] = response.data;
    const converted_data: entityRegistration[] = data.map((entry) => {
        return {
            ...entry,
            organization: organizationLinkFormatter(sector_code, sector_name, entry.organization!),
            lobby_firm:
                entry.lobby_firm === 'In-House'
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.consultant === 'In-House'
                    ? 'In-House'
                    : entry.lobby_firm === 'In-House'
                    ? entry.consultant
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/vwRg?regId=${entry.registration}&cno=${entry.corp}#regStart`,
            dashboard: `link:/registrations/${entry.registration}`,
        };
    });
    return converted_data;
};

// Fetch a sector data cluster of the given sector code, under a specific "type"
const fetchCluster = async (
    code: string | undefined,
    type: string,
    date: string | undefined
): Promise<entityCluster> => {
    // Fetch top 6 *type* within the given sector (determined by YTD communication count)
    const cluster_reponse = await axios.get(
        `${baseURL}/sector/${code}/cluster/${type}?date=${date}`
    );
    const cluster_data: entityCluster = cluster_reponse.data;

    return cluster_data;
};

// Fetch keywords from registrations in the given sector
const fetchTerms = async (code: string | undefined, date: string | undefined) => {
    const keyword_response = await axios.get(
        `${baseURL}/sector/${code}/terms?limit=6&date=${date}`
    );

    return keyword_response.data;
};

export const sectorAPI = {
    fetchUpdates,
    fetchOverview,
    fetchRecentLobbyingActivity,
    fetchRecentRegistrations,
    fetchRecentDeregistrations,
    fetchCluster,
    fetchTerms,
};
