import { Link, useNavigate } from 'react-router-dom';
import LoadPage from '../misc/LoadPage';
import { SubscriptionRequired } from '../misc/SubscriptionRequired';
import { useSelector } from 'react-redux';
import { selectUserLoading, selectUserPermissions } from '../../store/reducers/userSlice';
import { Fade, Breadcrumbs, Grow, IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useEffect, useState } from 'react';
import IssueCreationModal from './IssueCreationModal';
import {
    fetchCustomIssueList,
    selectCustomIssueList,
    selectIssueListLoading,
} from '../../features/issue-dashboards/store/issueListSlice';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { useAppDispatch } from '../../store/store';
import Modal from '../../components/misc/Modal';
import { issueEditorItem } from '../../features/issue-dashboards/interface/issue-entity.interface';
import { deleteIssue } from '../../features/issue-dashboards/store/issueSlice';

const CustomIssuePage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userLoading = useSelector(selectUserLoading);
    const issuesLoading = useSelector(selectIssueListLoading);
    const permissions = useSelector(selectUserPermissions);
    const selectedIssues: issueEditorItem[] = useSelector(selectCustomIssueList);

    const [creationModalOpen, setCreationModalOpen] = useState(false);
    const [activeIssueId, setActiveIssueId] = useState<number | null>(null);
    const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
    const [issueToDelete, setIssueToDelete] = useState<issueEditorItem | null>(null);

    useEffect(() => {
        dispatch(fetchCustomIssueList());
    }, [dispatch]);

    const openCreationModal = (selectedIssueId: number | null) => {
        setActiveIssueId(selectedIssueId);
        setCreationModalOpen(true);
    };

    const handleDelete = async (issueId: number) => {
        await dispatch(deleteIssue(issueId)).unwrap();
        await dispatch(fetchCustomIssueList()).unwrap();
        setConfirmationOpen(false);
    };

    return userLoading || issuesLoading ? (
        <LoadPage />
    ) : !permissions ? (
        <SubscriptionRequired />
    // ) : permissions.productType === ProductType.TRIAL ? (
    //     <div className="flex flex-col sm:flex-row items-center justify-center py-[50vh] text-lg">
    //         <img src={logo} className="h-20" alt="logo" />
    //         <div style={{ marginLeft: '20px', marginRight: '20px' }} />
    //         <p>Free Trial has no customizable settings.</p>
    //         <div className="flex flex-row items-center justify-center align-middle ml-2">
    //             <p>Click</p>
    //             <div
    //                 className="mx-1 text-cyan-500 font-bold hover:underline hover:cursor-pointer"
    //                 onClick={() => navigate('/')}
    //             >
    //                 here
    //             </div>
    //             <p>to go back.</p>
    //         </div>
    //     </div>
    ) : (
        <Fade in={true}>
            <div className="lg:py-32 py-20 grid grid-cols-12">
                <Breadcrumbs className="col-start-2 col-end-12" separator=">">
                    <Link to="/" className="hover:underline">
                        Lobby<b>IQ</b>
                    </Link>
                    <Link to="/account" className="hover:underline">
                        My Account
                    </Link>
                    <div>Custom Issue Creation</div>
                </Breadcrumbs>

                <IssueCreationModal
                    open={creationModalOpen}
                    activeIssueId={activeIssueId}
                    handleClose={() => setCreationModalOpen(!creationModalOpen)}
                />

                <Modal
                    open={confirmationOpen}
                    onClose={() => setConfirmationOpen(false)}
                    title={'Confirm Delete'}
                    width={25}
                >
                    <div className="flex flex-col">
                        <div className="mb-5">
                            Are you sure you would like to delete Issue
                            <div className="font-bold">{issueToDelete?.title}</div>
                        </div>
                    </div>
                    <div className="flex content-center">
                        <Button
                            fullWidth
                            variant={'contained'}
                            onClick={() => handleDelete(issueToDelete?.code || 0)}
                        >
                            Confirm
                        </Button>
                    </div>
                </Modal>

                <div className="sm:col-start-4 sm:col-end-10 col-start-3 col-end-11 mt-16">
                    <div className="text-4xl flex flex-row gap-2 items-center">
                        Custom Issue Dashboards
                    </div>
                </div>

                <div className="sm:col-start-4 sm:col-end-10 col-start-3 col-end-11 mt-6">
                    <div className="flex flex-row justify-between items-center">
                        <div className="text-2xl flex flex-row gap-2 items-center">Your Issues</div>
                    </div>
                </div>

                <div className="sm:col-start-4 sm:col-end-10 col-start-3 col-end-11 mt-6">
                    {selectedIssues?.map((entity) => (
                        <Grow in={true}>
                            <div className="flex flex-row justify-between bg-white shadow-sm rounded-md p-3 my-2 text-blue-400 font-medium">
                                <div
                                    className="hover:underline underline-offset-2 my-auto hover:cursor-pointer"
                                    onClick={() => openCreationModal(entity.code)}
                                >
                                    {entity.title}
                                </div>

                                <div className="flex flex-nowrap">
                                    <IconButton
                                        onClick={() => openCreationModal(entity.code)}
                                        aria-label="edit"
                                    >
                                        <EditNoteIcon color="primary" />
                                    </IconButton>
                                    <IconButton
                                        onClick={() =>
                                            navigate(
                                                `/issues/${entity.code}?name=${encodeURIComponent(
                                                    entity.title
                                                )}`
                                            )
                                        }
                                        aria-label="view"
                                    >
                                        <VisibilityIcon color="primary" />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            setIssueToDelete(entity);
                                            setConfirmationOpen(true);
                                        }}
                                        aria-label="delete"
                                    >
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </div>
                            </div>
                        </Grow>
                    ))}
                    {permissions['issues'] - selectedIssues.length > 0 && (
                        <Grow in={true}>
                            <div className="border border-1 border-slate-300 rounded-md p-3 my-6 text-blue-500 font-medium">
                                <div
                                    className="flex flex-row content-center gap-2 hover:cursor-pointer"
                                    onClick={() => openCreationModal(null)}
                                >
                                    Create Issue ({permissions['issues'] - selectedIssues.length}{' '}
                                    remaining)
                                    <AddToPhotosIcon />
                                </div>
                            </div>
                        </Grow>
                    )}
                </div>
            </div>
        </Fade>
    );
};

export default CustomIssuePage;
