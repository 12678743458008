import React, { useMemo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../../styles/flip_anim.css';

import { entityListExtra, entityListSimple } from '../../interfaces/generic-entity.interface';

import EntityListSearch from './EntityListSearch';
import { Selection } from '../../store/reducers/customSelectionSlice';
import LoadPage from '../misc/LoadPage';
import EntityCatalogueHeader from '../../features/entity-catalogue/EntityCatalogueHeader';
import EntityCatalogueBreadcrumbs from '../../features/entity-catalogue/EntityCatalogueBreadcrumbs';
import LiqPageContainer from '../../features/shared/UI/layout/LiqPageContainer';
import EntityCatalogueContext from '../../features/entity-catalogue/EntityCatalogueContext';
import EntityEntryOrchestrator from '../../features/entity-catalogue/EntityEntryOrchestrator';
import { EntityMap, EntityType } from '../../features/config/Entities';
import { ENTITY_CATALOGUE_NAME_DATA_KEY_MAP } from '../../features/entity-catalogue/EntityCatalogueConstants';

// Title and subtitle are optional components with defaults specified in function signature below.
interface entityListPageProps {
    type:
        | 'sectors'
        | 'institutions'
        | 'DPOHs'
        | 'senators'
        | 'MPs'
        | 'committees'
        | 'senate committees'
        | 'subjects'
        | 'issues'
        | 'organizations'
        | 'inactive organizations'
        | 'firms'
        | 'consultants';
    list: entityListExtra[];
    subList?: entityListSimple[];
    selections?: Selection[];
    defaultSortColumn?: string;
    defaultSortOrder?: 'asc' | 'desc';
    loading?: boolean;
}

const EntityListPage = ({
    type,
    list,
    subList,
    selections = [],
    defaultSortColumn = 'ttm',
    defaultSortOrder = 'desc',
    loading = false,
}: entityListPageProps) => {
    // Get the code and name of the current entity
    const url_params = useParams();
    const location = useLocation();

    const parent_code = url_params.entityId !== undefined ? url_params.entityId : '';
    const parent_name: string =
        new URLSearchParams(location.search).get(
            type === 'organizations' || type === 'inactive organizations'
                ? 'sector'
                : type === 'consultants'
                ? 'firm'
                : type === 'issues'
                ? 'subject'
                : type === 'MPs'
                ? 'committee'
                : 'institution'
        ) || '';

    // Set catalogue type
    let catalogueType = EntityMap[type];

    // Set up special house of commons MP case
    if (
        type === 'DPOHs' &&
        parent_code === '103' &&
        new URLSearchParams(location.search).get('title') === 'Member of Parliament'
    ) {
        catalogueType = EntityType.InstitutionMP;
    }

    const [expanded, setExpanded] = useState<boolean>(false);

    const [orderBy, setOrderBy] = useState<string>(defaultSortColumn);
    const [order, setOrder] = useState<'asc' | 'desc'>(defaultSortOrder);

    const handleSort = (property: any) => {
        const prop = ENTITY_CATALOGUE_NAME_DATA_KEY_MAP.get(property);
        const isAsc = orderBy === prop && order === 'asc';
        setOrderBy(prop);
        setOrder(isAsc ? 'desc' : 'asc');
    };

    const sorted_list = useMemo(() => {
        const selectedCodes = new Set(selections.map((s) => s.code || s.title));

        return [...list].sort((a: any, b: any) => {
            // if element is in selected options, move to top
            if (selectedCodes.has((a.code || a.title).toString())) {
                if (selectedCodes.has((b.code || b.title).toString())) {
                    if (order === 'asc') {
                        if (
                            ['title', 'affiliation', 'province_territory', 'constituency'].includes(
                                orderBy
                            )
                        )
                            return a[orderBy] > b[orderBy] ? 1 : -1;
                        else return +a[orderBy] > +b[orderBy] ? 1 : -1;
                    } else {
                        if (
                            ['title', 'affiliation', 'province_territory', 'constituency'].includes(
                                orderBy
                            )
                        )
                            return a[orderBy] < b[orderBy] ? 1 : -1;
                        else return +a[orderBy] < +b[orderBy] ? 1 : -1;
                    }
                }
                return -1;
            } else if (selectedCodes.has((a.code || a.title).toString())) {
                return 1;
            }

            // otherwise sort normally
            if (order === 'asc') {
                if (
                    ['title', 'affiliation', 'province_territory', 'constituency'].includes(orderBy)
                )
                    return a[orderBy] > b[orderBy] ? 1 : -1;
                else return +a[orderBy] > +b[orderBy] ? 1 : -1;
            } else {
                if (
                    ['title', 'affiliation', 'province_territory', 'constituency'].includes(orderBy)
                )
                    return a[orderBy] < b[orderBy] ? 1 : -1;
                else return +a[orderBy] < +b[orderBy] ? 1 : -1;
            }
        });
    }, [list, order, orderBy, selections]);

    return loading ? (
        <LoadPage />
    ) : (
        <LiqPageContainer>
            <EntityCatalogueBreadcrumbs
                catalogueType={type}
                catalogueParentCode={parent_code}
                catalogueParentName={parent_name}
            />

            <EntityCatalogueContext
                catalogueType={catalogueType}
                catalogueParentCode={parent_code}
                catalogueParentName={parent_name}
            />

            <hr className="col-start-2 col-end-12 h-0.5 my-3 bg-slate-100 rounded" />

            <div className="col-start-2 col-end-12 shadow-sm rounded-md">
                <Accordion
                    expanded={expanded}
                    onChange={() => setExpanded(!expanded)}
                    elevation={0}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Search</AccordionSummary>
                    <AccordionDetails>
                        <EntityListSearch
                            type={type}
                            list={sorted_list}
                            subList={subList}
                            embedded
                            parentCode={parent_code}
                            parentName={parent_name}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>

            <EntityCatalogueHeader
                catalogueType={catalogueType}
                catalogueLength={sorted_list.length}
                catalogueSortingField={orderBy}
                catalogueSortingDirection={order}
                handleCatalogueSort={handleSort}
            />

            <hr className="col-start-2 col-end-12 h-0.5 my-3 bg-slate-100 rounded" />

            <EntityEntryOrchestrator
                catalogueType={catalogueType}
                catalogueParentCode={parent_code}
                catalogueParentName={parent_name}
                catalogueEntries={sorted_list}
                catalogueSelections={selections}
            />
        </LiqPageContainer>
    );
};

export default EntityListPage;
