import React from 'react';
import { entityListExtra } from '../../interfaces/generic-entity.interface';
import { ENTITY_CATALOGUE_FIELD_MAP } from './EntityCatalogueConstants';
import { EntityType } from '../config/Entities';
import EntityCatalogueItemCreator from './EntityCatalogueItemCreator';
import LoadingList from '../../components/generic-dashboards/loading/LoadingList';
import { Selection } from '../../store/reducers/customSelectionSlice';

interface CatalogueEntryOrchestratorProps {
    catalogueType: EntityType;
    catalogueParentCode: string;
    catalogueParentName: string;
    catalogueEntries: entityListExtra[];
    // items to be highlighted
    catalogueSelections: Selection[];
}

const EntityEntryOrchestrator = ({
    catalogueType,
    catalogueParentCode,
    catalogueParentName,
    catalogueEntries,
    catalogueSelections,
}: CatalogueEntryOrchestratorProps) => {
    let fields: string[] | undefined;

    fields = ENTITY_CATALOGUE_FIELD_MAP.get(catalogueType);

    return (
        <div className="col-start-2 col-end-12">
            <EntityCatalogueItemCreator
                catalogueType={catalogueType}
                catalogueParentCode={catalogueParentCode}
                catalogueParentName={catalogueParentName}
                catalogueEntries={catalogueEntries}
                catalogueSelections={catalogueSelections}
                catalogueFields={fields || []}
            />
            {catalogueEntries.length === 0 && <LoadingList />}
        </div>
    );
};

export default EntityEntryOrchestrator;
