import React, { useState } from 'react';
import { Fade, Grow } from '@mui/material';
import { MissingData } from '../../../components/misc/MissingData';
import HelpIcon from '@mui/icons-material/Help';
import { useSelector } from 'react-redux';
import { GATrackDashboardEvent, EVENT_TYPES } from '../../../google_analytics/TrackEvent';
import { selectEmail, selectUserProductType } from '../../../store/reducers/userSlice';
import Modal from '../../../components/misc/Modal';
import LoadingTable from '../../../components/generic-dashboards/loading/LoadingTable';
import Table from '../../../components/generic-dashboards/Table';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';
import {
    mostActiveDpoh,
    mostActiveInstitution,
    mostActiveOrg,
    recentRegistrants,
} from '../../../components/whats-new/IssueWhatsNew';

interface DashTableWithBulletsProps {
    dashboard?: string;
    topic?: string;
    titleText?: string;
    title?: any;
    subtitle?: string;
    tableData: any;
    loading: boolean;
    updates: recentUpdates | null;
    updatesLoading: boolean;
    tableHeader: any;
    suffixes?: any;
    defaultSort?: string;
    defaultNumRows?: number;
    embedded?: boolean;
    tooltipContent?: JSX.Element;
}

const IssueDashTableWithBullets = ({
    dashboard = '',
    topic = '',
    titleText = '',
    title = '',
    subtitle = '',
    tableData,
    loading,
    updates,
    updatesLoading,
    tableHeader,
    suffixes = [],
    defaultSort = tableHeader[0],
    defaultNumRows = 5,
    embedded = false,
    tooltipContent,
}: DashTableWithBulletsProps) => {
    const align = 'left';
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const userEmail = useSelector(selectEmail);
    const userProductType = useSelector(selectUserProductType);
    const TrackEvent = (name: string) => {
        GATrackDashboardEvent(
            name,
            EVENT_TYPES.CLICK,
            dashboard,
            titleText === '' ? title : titleText,
            topic,
            userEmail ? userEmail : undefined,
            userProductType ? userProductType : undefined
        );
    };

    return (
        <Fade in={true}>
            <div>
                <div className="flex lg:flex-row flex-col justify-between">
                    <div className="text-xl">{title}</div>
                    <div className="flex flex-row items-center">
                        <div className="text-lg font-light">{subtitle}</div>
                        {tooltipContent && (
                            <div
                                className="ml-2 hover:cursor-pointer"
                                onClick={() => setModalOpen(true)}
                            >
                                <HelpIcon color="info" />
                            </div>
                        )}
                    </div>
                </div>

                {tooltipContent && (
                    <Modal
                        title={title}
                        open={modalOpen}
                        width={50}
                        onClose={() => setModalOpen(false)}
                    >
                        {tooltipContent}
                    </Modal>
                )}

                <hr className="h-0.5 my-3 bg-slate-100 rounded" />

                {updatesLoading ? (
                    <></>
                ) : !updatesLoading && !updates ? (
                    <MissingData />
                ) : updates ? (
                    <ul className="my-6 list-disc list-inside">
                        <li>
                            {recentRegistrants(updates.newRegistrants, updates.month)}{' '}
                            {mostActiveOrg(updates)}
                        </li>
                        <li>
                            {updates.mostActiveInstitution ? mostActiveInstitution(updates) : <></>}{' '}
                            {mostActiveDpoh(updates)}
                        </li>
                    </ul>
                ) : (
                    <></>
                )}

                {loading ? (
                    <LoadingTable />
                ) : !loading && !tableData ? (
                    <MissingData />
                ) : tableData ? (
                    <Grow in={true}>
                        <div>
                            <Table
                                rowData={tableData}
                                rowHeaders={tableHeader}
                                alignment={align}
                                paginated={true}
                                suffixes={suffixes}
                                defaultSort={defaultSort}
                                defaultNumRows={defaultNumRows}
                                embedded={embedded}
                                trackClickEvent={TrackEvent}
                            />
                        </div>
                    </Grow>
                ) : (
                    <></>
                )}
            </div>
        </Fade>
    );
};

export default IssueDashTableWithBullets;
