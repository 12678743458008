import React, { useCallback, useEffect, useState } from 'react';
import { default as MUITable } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TablePagination, TableSortLabel, styled } from '@mui/material';
import { replace_underscores_capitalize } from '../../helpers/string_formatters';
import { Link } from 'react-router-dom';
import { LinkFormatter } from '../shared/routing/LinkFormatter';

const StyledTableCell = styled(TableCell)({
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: 14,
});

// rowData and rowHeaders are required parameters
interface tableProps {
    rowHeaders: Array<string>;
    rowData: Array<any>;
    shortHeaders?: Array<string>;
    rigidHeaders?: Array<string>;
    indentValues?: Array<string>;
    alignment?: 'right' | 'center' | 'left' | 'inherit' | 'justify' | undefined;
    defaultSort?: string;
    defaultSortOrder?: 'asc' | 'desc';
    defaultNumRows?: number;
    paginated?: boolean;
    embedded?: boolean;
    simplePagination?: boolean;
    disableSorting?: boolean;
    suffixes?: any;
    externalSpyRowsPerPage?: (rowNum: number) => void;
    externalSpyPage?: (pageNum: number) => void;
    externalSpySortedData?: (data: Array<any>) => void;
    trackClickEvent?: (name: string) => void;
}

const Table = ({
    rowData,
    rowHeaders,
    shortHeaders = [],
    rigidHeaders = ['date'],
    indentValues = [],
    alignment = 'right',
    defaultSort = rowHeaders[0],
    defaultSortOrder = 'desc',
    defaultNumRows = 5,
    paginated = false,
    embedded = false,
    simplePagination = false,
    disableSorting = false,
    suffixes = [],
    // optional Spy functions to track change of these parameters in the parent component
    externalSpyRowsPerPage = undefined,
    externalSpyPage = undefined,
    externalSpySortedData = undefined,
    trackClickEvent = (name: string) => {},
}: tableProps) => {
    const [orderBy, setOrderBy] = useState<string>(defaultSort);
    const [order, setOrder] = useState<'asc' | 'desc'>(defaultSortOrder);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultNumRows);

    const sortedRowData = useCallback(
        () =>
            [...rowData].sort((a: any, b: any) => {
                if (order === 'asc') {
                    if (typeof a[orderBy] === 'string') return a[orderBy] > b[orderBy] ? 1 : -1;
                    else return +a[orderBy] > +b[orderBy] ? 1 : -1;
                } else {
                    if (typeof a[orderBy] === 'string') return a[orderBy] < b[orderBy] ? 1 : -1;
                    else return +a[orderBy] < +b[orderBy] ? 1 : -1;
                }
            }),
        [order, orderBy, rowData]
    );

    useEffect(() => {
        externalSpySortedData && externalSpySortedData(sortedRowData());
    }, [externalSpySortedData, sortedRowData]);

    const handleChangePage = (event: unknown, newPage: number) => {
        externalSpyPage && externalSpyPage(newPage);
        trackClickEvent(`Table page change: (${page} to ${newPage})`);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        externalSpyRowsPerPage && externalSpyRowsPerPage(+event.target.value);
        externalSpyPage && externalSpyPage(0);
        trackClickEvent(`Table row change: (${rowsPerPage} to ${+event.target.value})`);

        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSort = (property: any) => {
        if (!disableSorting) {
            const isAsc = orderBy === property && order === 'asc';
            setOrderBy(property);
            setOrder(isAsc ? 'desc' : 'asc');
        }
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0;

    return (
        <div className={`${!embedded && 'bg-white shadow-md rounded-md min-h-full'} overflow-auto`}>
            <TableContainer>
                <MUITable size="small">
                    <TableHead>
                        <TableRow>
                            {rowHeaders.map((header, idx) => (
                                <StyledTableCell key={idx} align={alignment}>
                                    <TableSortLabel
                                        active={orderBy === header}
                                        onClick={() => {
                                            trackClickEvent('Table sort by: ' + header);
                                            handleSort(header);
                                        }}
                                        direction={orderBy === header ? order : 'asc'}
                                    >
                                        {replace_underscores_capitalize(header)}
                                    </TableSortLabel>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRowData()
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, idx) => (
                                <TableRow
                                    key={idx}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    {rowHeaders.map((val: any, idx) =>
                                        typeof row[val] === 'string' &&
                                        row[val].startsWith('https') ? (
                                            <StyledTableCell key={idx} align="left">
                                                <a
                                                    className="text-blue-500 hover:underline underline-offset-2"
                                                    href={row[val].toString()}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    onClick={() =>
                                                        trackClickEvent(
                                                            `Table redirect by View: ${row['client']}`
                                                        )
                                                    }
                                                >
                                                    View
                                                </a>
                                            </StyledTableCell>
                                        ) : typeof row[val] === 'string' &&
                                          row[val].startsWith('link:') ? (
                                            <StyledTableCell key={idx} align="left">
                                                <Link
                                                    className="text-blue-500 hover:underline underline-offset-2"
                                                    to={row[val].slice(5).toString()}
                                                    onClick={() =>
                                                        trackClickEvent(
                                                            `Table redirect by View: ${row['client']}`
                                                        )
                                                    }
                                                >
                                                    View
                                                </Link>
                                            </StyledTableCell>
                                        ) : typeof row[val] === 'string' &&
                                          row[val].startsWith('URL') ? (
                                            <StyledTableCell key={idx} align="left">
                                                <LinkFormatter urlFormatStr={row[val]} />
                                            </StyledTableCell>
                                        ) : shortHeaders.includes(val) ? (
                                            <StyledTableCell key={idx} align={alignment}>
                                                <div className="lg:whitespace-normal whitespace-nowrap">
                                                    {row[val]}
                                                </div>
                                            </StyledTableCell>
                                        ) : rigidHeaders.includes(val) ? (
                                            <StyledTableCell key={idx} align={alignment}>
                                                <div className="lg:whitespace-normal whitespace-nowrap w-20">
                                                    {row[val]}
                                                </div>
                                            </StyledTableCell>
                                        ) : (
                                            <StyledTableCell key={idx} align={alignment}>
                                                <div
                                                    className={
                                                        indentValues.includes(row[val])
                                                            ? 'ml-4'
                                                            : ''
                                                    }
                                                >
                                                    {row[val]}
                                                    {val in suffixes ? suffixes[val] : ''}
                                                </div>
                                            </StyledTableCell>
                                        )
                                    )}
                                </TableRow>
                            ))}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 33 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={Object.keys(rowData[0]).length} />
                            </TableRow>
                        )}
                        {rowData.length === 0 && (
                            <TableRow
                                style={{
                                    height: 33 * 5,
                                }}
                            >
                                <TableCell colSpan={rowHeaders.length}>
                                    <div className="text-center">No data</div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </MUITable>
            </TableContainer>
            <div className={paginated ? '' : 'hidden'}>
                <TablePagination
                    labelRowsPerPage={simplePagination ? '' : 'Rows:'}
                    labelDisplayedRows={({ from, to, count }) =>
                        simplePagination
                            ? ''
                            : `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
                    }
                    rowsPerPageOptions={simplePagination ? [] : [5, 10, 25, 50]}
                    component="div"
                    count={rowData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

export default Table;
