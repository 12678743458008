import React, { useCallback, useEffect, useState } from 'react';
import Dashboard from '../../../pages/dashboards/Dashboard';
import { useParams, useSearchParams } from 'react-router-dom';
import { bumperSuperList } from '../../../interfaces/bumper-types.interface';
import {
    entityCluster,
    entityLobbyingActivity,
    entityOverviews,
    entityRegistration,
} from '../../../interfaces/generic-entity.interface';

import { useAppDispatch } from '../../../store/store';
import {
    fetchSectorOverview,
    selectSectorOverview,
    fetchSectorRecentLobbying,
    selectSectorRecentLobbying,
    fetchSectorRegistrations,
    selectSectorRegistrations,
    fetchSectorDeregistrations,
    selectSectorDeregistrations,
    fetchOrganizationCluster,
    selectOrganizationCluster,
    selectInstitutionCluster,
    fetchInstitutionCluster,
    selectDPOHCluster,
    fetchDPOHCluster,
    fetchFirmCluster,
    selectFirmCluster,
    fetchSubjectCluster,
    selectSubjectCluster,
    selectSectorOverviewLoading,
    selectSectorRecentLobbyingLoading,
    selectSectorRegistrationsLoading,
    selectSectorDeregistrationsLoading,
    selectOrganizationClusterLoading,
    selectFirmClusterLoading,
    selectDPOHClusterLoading,
    selectSubjectClusterLoading,
    selectInstitutionClusterLoading,
    fetchSectorTerms,
    selectSectorTerms,
    selectSectorTermsLoading,
    selectSectorUpdates,
    selectSectorUpdatesLoading,
    fetchSectorUpdates,
    selectSectorExportLoading,
    selectSectorExportFail,
    fetchMPCluster,
    selectMPCluster,
    selectMPClusterLoading,
} from '../store/sectorSlice';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
    selectChosenSectors,
    selectSectorsLoading,
} from '../../../store/reducers/customSelectionSlice';
import { DashboardNotLicenced } from '../../../pages/misc/DashboardNotLicenced';
import { ProductType, selectUserPermissions } from '../../../store/reducers/userSlice';
import LoadPage from '../../../pages/misc/LoadPage';
import { SectorOrganizations } from '../properties/SectorOrganizations';
import { SectorGovernmentInstitutions } from '../properties/SectorGovernmentInstitutions';
import { SectorPublicOffice } from '../properties/SectorPublicOffice';
import { SectorSubjects } from '../properties/SectorSubjects';
import { SectorLobbyFirms } from '../properties/SectorLobbyFirms';
import { MissingDashboardSelections } from '../../../pages/misc/MissingDashboardSelections';
import { recentUpdates } from '../../shared/interfaces/whats-new.interface';

const SectorDataLayer = () => {
    const dispatch = useAppDispatch();

    // Get the id of the current entity
    const url_params = useParams();
    const [searchParams] = useSearchParams();

    const entity_code: string = url_params.entityId !== undefined ? url_params.entityId : '';

    const entity_name: string = searchParams.get('name') || '';

    const updates: recentUpdates | null = useSelector(selectSectorUpdates);
    const updates_loading: boolean = useSelector(selectSectorUpdatesLoading);

    const overview: entityOverviews | null = useSelector(selectSectorOverview);
    const overview_loading: boolean = useSelector(selectSectorOverviewLoading);

    const activity: entityLobbyingActivity[] | null = useSelector(selectSectorRecentLobbying);
    const activity_loading: boolean = useSelector(selectSectorRecentLobbyingLoading);

    const registrations: entityRegistration[] | null = useSelector(selectSectorRegistrations);
    const registrations_loading: boolean = useSelector(selectSectorRegistrationsLoading);

    const deregistrations: entityRegistration[] | null = useSelector(selectSectorDeregistrations);
    const deregistrations_loading: boolean = useSelector(selectSectorDeregistrationsLoading);

    const org_cluster: entityCluster | null = useSelector(selectOrganizationCluster);
    const org_cluster_loading: boolean = useSelector(selectOrganizationClusterLoading);

    const institution_cluster: entityCluster | null = useSelector(selectInstitutionCluster);
    const institution_cluster_loading: boolean = useSelector(selectInstitutionClusterLoading);

    const firm_cluster: entityCluster | null = useSelector(selectFirmCluster);
    const firm_cluster_loading: boolean = useSelector(selectFirmClusterLoading);

    const dpoh_cluster: entityCluster | null = useSelector(selectDPOHCluster);
    const dpoh_cluster_loading: boolean = useSelector(selectDPOHClusterLoading);

    const subject_cluster: entityCluster | null = useSelector(selectSubjectCluster);
    const subject_cluster_loading: boolean = useSelector(selectSubjectClusterLoading);

    const mp_cluster: entityCluster | null = useSelector(selectMPCluster);
    const mp_cluster_loading: boolean = useSelector(selectMPClusterLoading);

    const terms: bumperSuperList[] | null = useSelector(selectSectorTerms);
    const terms_loading: boolean = useSelector(selectSectorTermsLoading);

    const sectorSelections = useSelector(selectChosenSectors);
    const sectorSelectionsLoading = useSelector(selectSectorsLoading);
    const userPermissions = useSelector(selectUserPermissions);

    const export_loading = useSelector(selectSectorExportLoading);
    const export_fail = useSelector(selectSectorExportFail);

    const [date, setDate] = useState<string>(moment.utc(moment()).format('YYYY-MM-DD'));

    const canAccessSelection = useCallback(() => {
        if (
            userPermissions?.productType === ProductType.ENTERPRISE ||
            userPermissions?.productType === ProductType.TRIAL
        ) {
            return true;
        } else return !!sectorSelections.find((selection) => selection.code === entity_code);
    }, [userPermissions, sectorSelections, entity_code]);

    useEffect(() => {
        // Sector selections fetched in Navbar
        if (!sectorSelectionsLoading && canAccessSelection()) {
            dispatch(fetchSectorUpdates({ code: entity_code, date: date }));
            dispatch(fetchSectorOverview({ code: entity_code, date: date }));
            dispatch(
                fetchSectorRecentLobbying({
                    sector_code: entity_code,
                    sector_name: entity_name,
                    date: date,
                })
            );
            dispatch(
                fetchSectorRegistrations({
                    sector_code: entity_code,
                    sector_name: entity_name,
                    date: date,
                })
            );
            dispatch(
                fetchSectorDeregistrations({
                    sector_code: entity_code,
                    sector_name: entity_name,
                    date: date,
                })
            );
            dispatch(fetchOrganizationCluster({ code: entity_code, date: date }));
            dispatch(fetchInstitutionCluster({ code: entity_code, date: date }));
            dispatch(fetchFirmCluster({ code: entity_code, date: date }));
            dispatch(fetchDPOHCluster({ code: entity_code, date: date }));
            dispatch(fetchSubjectCluster({ code: entity_code, date: date }));
            dispatch(fetchSectorTerms({ code: entity_code, date: date }));
            dispatch(fetchMPCluster({ code: entity_code, date: date }));
        }
    }, [dispatch, entity_code, entity_name, date, canAccessSelection, sectorSelectionsLoading]);

    const overview_headers = ['count'];

    // Get the current year
    const currentYear = moment(date, 'YYYY-MM-DD').year();

    // Populate the list with the last 8 years
    for (let i = currentYear; i >= currentYear - 6; i--) {
        overview_headers.push(String(i));
    }

    return canAccessSelection() ? (
        <Dashboard
            type="sectors"
            entity_code={entity_code}
            date={date}
            handleDateChange={setDate}
            includeUpdates
            updates={updates}
            updatesLoading={updates_loading}
            includeOverview
            overview={overview}
            overviewHeaders={overview_headers}
            overviewLoading={overview_loading}
            overviewIndent={['by Consultants', 'of Ministers or DMs']}
            includeRecentLobbying
            recentLobbying={activity}
            recentLobbyingLoading={activity_loading}
            includeRegistrations
            recentRegistrations={registrations}
            recentRegistrationsLoading={registrations_loading}
            includeDeregistrations
            recentDeregistrations={deregistrations}
            recentDeregistrationsLoading={deregistrations_loading}
            includeTopKeywords
            bumperData={terms}
            bumperDataLoading={terms_loading}
            exportLoading={export_loading}
            exportFail={export_fail}
            clusters={[
                {
                    cluster_data: org_cluster,
                    title: 'Organizations',
                    tooltipContent: SectorOrganizations,
                    loading: org_cluster_loading,
                },
                {
                    cluster_data: institution_cluster,
                    title: 'Government Institutions',
                    tooltipContent: SectorGovernmentInstitutions,
                    loading: institution_cluster_loading,
                },
                {
                    cluster_data: dpoh_cluster,
                    title: 'Designated Public Office Holders',
                    tooltipContent: SectorPublicOffice,
                    loading: dpoh_cluster_loading,
                },
                {
                    cluster_data: mp_cluster,
                    title: 'MPs',
                    tooltipContent: SectorPublicOffice,
                    loading: mp_cluster_loading,
                },
                {
                    cluster_data: subject_cluster,
                    title: 'Subjects',
                    tooltipContent: SectorSubjects,
                    loading: subject_cluster_loading,
                },
                {
                    cluster_data: firm_cluster,
                    title: 'Lobby Firms',
                    tooltipContent: SectorLobbyFirms,
                    loading: firm_cluster_loading,
                },
            ]}
        />
    ) : sectorSelectionsLoading ? (
        <LoadPage />
    ) : sectorSelections.length === 0 ? (
        <MissingDashboardSelections type="sectors" />
    ) : (
        <DashboardNotLicenced type="sectors" name={entity_name} />
    );
};

export default SectorDataLayer;
