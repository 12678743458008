import React from 'react';
import LoadingIssueRankings from '../../../components/generic-dashboards/loading/LoadingIssueRankings';
import { MissingData } from '../../../components/misc/MissingData';
import { issueInfo } from '../interface/issue-entity.interface';
import LiqExhibitTitleContainer from '../../shared/UI/layout/LiqExhibitTitleContainer';

// Title and subtitle are optional components with defaults specified in function signature below.
interface keywordCompositionProps {
    title?: string;
    subtitle?: string;
    keywordData: issueInfo | null;
    loading: boolean;
}

const KeywordComposition = ({
    title = '',
    subtitle = '',
    keywordData,
    loading,
}: keywordCompositionProps) => {
    return (
        <div>
            <LiqExhibitTitleContainer title={`Issue Dashboard: ${title}`} subtitle={subtitle} />
            <hr className="h-0.5 my-3 bg-slate-100 rounded" />
            {loading ? (
                <LoadingIssueRankings />
            ) : !loading && !keywordData ? (
                <MissingData />
            ) : keywordData ? (
                <span className="text-lg">
                    <b>Issue keywords: </b>
                    {keywordData.keywords.join(', ')}
                </span>
            ) : (
                <></>
            )}
        </div>
    );
};

export default KeywordComposition;
